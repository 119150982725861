const config = {
  apiUrl:
    process.env.REACT_APP_API_URL ||
    "https://almanakt-api-v2.azurewebsites.net/api/",
  strava: {
    clientID: "77842",
    scope: "activity:read_all",
  },
};

module.exports = config