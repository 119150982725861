import React from 'react';

import { MoonTile } from './MoonTile';
import { TideTile } from './TideTile';
import { HealthTile } from './HealthTile';
import { RunningTile } from './RunningTile';

import Chip from '@mui/material/Chip';
import { FlexWrapContainer } from '../structure/FlexWrapContainer';

function selectOn(view, match) {
  return view === match ? 'outlined' : ''
}

function SelectChip({ label, type, view, onClick }) {
  return <Chip sx={{ m: 1 }} label={label} variant={selectOn(view, type)} onClick={onClick} />
}

function filterOnMiles(day, filter) {
  if (filter === undefined) { return true }
  return day.running?.filter(r => r.milesString === filter).length > 0
}

export default function DayCalendarUx({ days, view, year, setView, setYear, milesFilter, setMilesFilter }) {
  return (
    <>
      <FlexWrapContainer>
        <SelectChip label="2022" type="2022" view={year} onClick={() => setYear('2022')} />
        <SelectChip label="2023" type="2023" view={year} onClick={() => setYear('2023')} />
        <SelectChip label="2024" type="2024" view={year} onClick={() => setYear('2024')} />
      </FlexWrapContainer>
      <FlexWrapContainer>
        <SelectChip label="Health" type="health" view={view} onClick={() => setView('health')} />
        <SelectChip label="Running" type="running" view={view} onClick={() => setView('running')} />
        <SelectChip label="Moon" type="moon" view={view} onClick={() => setView('moon')} />
        <SelectChip label="Tides" type="tides" view={view} onClick={() => setView('tides')} />
        <SelectChip label="4" type="miles4" view={view} onClick={() => setMilesFilter('4.0')} />
        <SelectChip label="5" type="miles5" view={view} onClick={() => setMilesFilter('5.0')} />
        <SelectChip label="6" type="miles6" view={view} onClick={() => setMilesFilter('6.0')} />
        <SelectChip label="7" type="miles7" view={view} onClick={() => setMilesFilter('7.0')} />
        <SelectChip label="*" type="milesAll" view={view} onClick={() => setMilesFilter(undefined)} />
      </FlexWrapContainer>
      <FlexWrapContainer>
        {view === 'moon' && days.filter(d => d.moon).map(day => <MoonTile day={day} key={day.date}></MoonTile>)}
        {view === 'tides' && days.filter(d => d.tides).map(day => <TideTile day={day} key={day.date}></TideTile>)}
        {view === 'health' && days.map(day => <HealthTile activity={day} key={day.date}></HealthTile>)}
        {view === 'running' && days.filter(d => filterOnMiles(d, milesFilter)).map(day => <RunningTile day={day} key={day.date}></RunningTile>)}
      </FlexWrapContainer>
    </>
  );
}
